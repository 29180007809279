// import image1 from '../images/confirmed/1.jpeg';
// import image2 from '../images/confirmed/2.jpeg';
// import image3 from '../images/confirmed/3.jpeg';
// import image4 from '../images/confirmed/4.jpeg';
// import image5 from '../images/confirmed/5.jpeg';
// import image6 from '../images/confirmed/6.jpeg';
// import image7 from '../images/confirmed/7.jpeg';
// import image8 from '../images/confirmed/8.jpeg';
// import image9 from '../images/confirmed/9.jpeg';
// import image10 from '../images/confirmed/10.jpeg';
// import image11 from '../images/confirmed/11.jpeg';
// import image12 from '../images/confirmed/12.jpeg';
// import image13 from '../images/confirmed/13.jpeg';
// import image14 from '../images/confirmed/14.jpeg';
// import image15 from '../images/confirmed/15.jpeg';
// import image16 from '../images/confirmed/16.jpeg';
// import image17 from '../images/confirmed/17.jpeg';
// import image18 from '../images/confirmed/18.jpeg';
// import image19 from '../images/confirmed/19.jpeg';
// import image20 from '../images/confirmed/20.jpeg';
// import image21 from '../images/confirmed/21.jpeg';
// import image22 from '../images/confirmed/22.jpeg';
// import image23 from '../images/confirmed/23.jpeg';


import i1 from '../images/common/1.png';
import i2 from '../images/common/2.png';
import i3 from '../images/common/3.png';


// export const confirmed = [
//     image1, image2, image3, image4, image5, image6, image7, image8, image9, image10,
//     image11, image12, image13, image14, image15, image16, image17, image18, image19, image20,
//     image21, image22, image23
// ];

export const common = [i1, i2, i3];